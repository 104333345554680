<template>
  <b-overlay :show="show" variant="dark" no-wrap fixed opacity="0" z-index="1020" :class="color"></b-overlay>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      timerState: null,
      isMountedRecently: true,
    };
  },
  mounted() {
    this.timerHandle();
  },
  computed: {
    ...mapState(["axios"]),
    color() {
      return this.axios.isHTTPReading
        ? "overlay text-danger"
        : "overlay text-warning";
    },
    show() {
      if (this.axios.isHTTPReading || this.axios.isHTTPBusy) {
        this.timerHandle();
        return true;
      } else {
        return this.axios.startBusy;
      }
    },
  },
  methods: {
    timerHandle() {
      clearTimeout(this.timerState);
      this.timerState = setTimeout(() => {
        if (this.axios.isHTTPReading && this.isMountedRecently) {
          // IMPORTANT
          // Si le jeton a été refusé, ces instructions
          // permettront d'effacer le jeton stocké en localStorage
          // pour en définir un nouveau
          localStorage.setItem("jwt", "");
          this.$root.renewToken();
        }

        this.$store.dispatch("axios/http_busy", false);
        this.$store.dispatch("axios/http_reading", false);

        this.isMountedRecently = false;
      }, 5000);
    },
  },
};
</script>

<style scoped>
.overlay {
  font-size: 0.5em;
  transform: scale(3);
}
</style>
